<!--
  + 超级管理员和管理员的首页
 -->
<template>
  <div class="home page_width" v-loading="loading">
    <div class="home_header">
      <div class="header_left">
        <h1>欢迎回到服务平台后台</h1>
        <p>今天是{{ curDate | dateFormat('YYYY年MM月DD日') }}</p>
      </div>
      <div class="header_right">
        <div class="right_item">
          <div class="icon_box"><i class="iconfont iconhoutaitubiao5"></i></div>
          <div class="number_box">
            <p>{{ dataSum.totalOrg0Num }}</p>
            <p>一般机构</p>
          </div>
        </div>
        <div class="right_item">
          <div class="icon_box"><i class="iconfont iconhoutaitubiao5"></i></div>
          <div class="number_box">
            <p>{{ dataSum.totalOrg1Num }}</p>
            <p>服务机构</p>
          </div>
        </div>
        <div class="right_item">
          <div class="icon_box"><i class="iconfont iconhoutaitubiao5"></i></div>
          <div class="number_box">
            <p>{{ dataSum.totalReq }}</p>
            <p>需求资源</p>
          </div>
        </div>
        <div class="right_item">
          <div class="icon_box"><i class="iconfont iconhoutaitubiao5"></i></div>
          <div class="number_box">
            <p>{{ dataSum.totalRes }}</p>
            <p>服务产品</p>
          </div>
        </div>
      </div>
    </div>
    <div class="home_main">
      <div class="module_box system_entrance_box tw-mb-20px">
        <div class="module_box_header">
          <p>平台系统入口</p>
        </div>
        <div class="module_box_main">
          <div class="entrance_list">
            <div class="system_entrance" @click="$router.push('/resource-require/service')">
              <i class="iconfont iconhoutaitubiao6"></i>
              <p>服务资源集成管理与快速发布系统</p>
            </div>
            <div class="system_entrance" @click="onGoSearch">
              <i class="iconfont iconhoutaitubiao4"></i>
              <p>科技服务资源搜索与推荐系统</p>
            </div>
            <div class="system_entrance" @click="$router.push('/data')">
              <i class="iconfont iconhoutaitubiao3"></i>
              <p>科技服务匹配与撮合交易系统</p>
            </div>
            <div class="system_entrance" @click="$router.push('/service-chain-manage')">
              <i class="iconfont iconhoutaitubiao1"></i>
              <p>服务协同与服务链定制系统</p>
            </div>
            <div class="system_entrance" @click="$router.push('/org-credit')">
              <i class="iconfont iconhoutaitubiao2"></i>
              <p>服务信用评价与监管系统</p>
            </div>
          </div>
        </div>
      </div>
      <div class="statistics">
        <div class="module_box chart_module" style="width: 920px;">
          <div class="module_box_header">
            <p>活跃情况统计</p>
          </div>
          <div class="chart_main">
            <div class="main_header">
              <el-date-picker
                size="small"
                type="daterange"
                v-model="searchDate"
                :clearable="false"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                range-separator="~"
                @change="getActiveChartData">
              </el-date-picker>
            </div>
            <div class="chart_pane" ref="chartPane"></div>
          </div>
        </div>
        <div>
          <div class="module_box total" style="width: 260px;">
            <div class="module_box_header">
              <p>交易总量情况</p>
            </div>
            <div class="total_main">
              <div class="total_item">
                <div class="top_box">
                  <p>累计成交额</p>
                  <div>
                    <h1>
                      {{ tradedData.totalTrade }}<span>元</span>
                    </h1>
                  </div>
                </div>
                <!-- 上涨就红色，下降就黑色 -->
                <div class="footer_box">
                  <div class="footer_item">
                    <div class="label">季度占比</div>
                    <div class="value down">
                      <p>{{ tradedData.tradeQuarterRatio }}%</p>
                      <!-- <i class="iconfont iconshangsheng"></i> -->
                    </div>
                  </div>
                  <div class="footer_item">
                    <div class="label">年度占比</div>
                    <div class="value">
                      <p>{{ tradedData.tradeYearRatio }}%</p>
                      <!-- <i class="iconfont iconshangsheng"></i> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="total_item">
                <div class="top_box">
                  <p>累计订单量</p>
                  <div>
                    <h1>
                      {{ tradedData.totalOrder }}<span>笔</span>
                    </h1>
                  </div>
                </div>
                <!-- 上涨就红色，下降就黑色 -->
                <div class="footer_box">
                  <div class="footer_item">
                    <div class="label">季度占比</div>
                    <div class="value down">
                      <p>{{ tradedData.orderQuarterRatio }}%</p>
                      <!-- <i class="iconfont iconshangsheng"></i> -->
                    </div>
                  </div>
                  <div class="footer_item">
                    <div class="label">年度占比</div>
                    <div class="value">
                      <p>{{ tradedData.orderYearRatio }}%</p>
                      <!-- <i class="iconfont iconshangsheng"></i> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import dayjs from 'dayjs'
import * as api from '@/api'
import geLineOptions from './line-options'
export default {
  data () {
    return {
      // 当前时间
      curDate: new Date(),
      // 页面loading
      loading: false,
      // 搜索日期
      searchDate: [],
      // 图表实例
      chart: null,
      // 数据合计
      dataSum: {
        totalOrg0Num: 0, // 一般机构数量
        totalOrg1Num: 0, // 服务机构数量
        totalReq: 0, // 需求数量
        totalRes: 0 // 资源数量
      },
      // 交易量
      tradedData: {
        totalTrade: 0, // 累计成交额
        tradeQuarterRatio: 0, // 成交额季度占比
        tradeYearRatio: 0, // 成交额年度占比
        totalOrder: 0, // 累计订单量
        orderQuarterRatio: 0, // 订单量季度占比
        orderYearRatio: 0 // 订单量年度占比
      }
    }
  },
  computed: {
    // 官网地址 -> 资源搜索模块会进入官网
    homePahe () {
      return process.env.VUE_APP_HOME_PATH
    }
  },
  async mounted () {
    this.loading = true
    await this.getDatasum()
    await this.getTraded()
    await this.getActiveChartData()
    this.loading = false
  },
  methods: {
    // 获取数据合计统计
    async getDatasum () {
      await api.getDatasum().then(res => {
        if (res.data.code === 0) {
          console.log('数据合计', res.data.data)
          this.dataSum = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    },
    // 获取交易量统计
    async getTraded () {
      await api.getTraded().then(res => {
        if (res.data.code === 0) {
          console.log('交易量', res.data.data)
          this.tradedData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    },
    // 根据时间
    async getActiveChartData () {
      // 如果未进行筛选，则从月初至今
      if (this.searchDate.length === 0) {
        var date = new Date()
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        this.searchDate = [firstDay, date]
      }
      await api.getActiveChartData({
        start: dayjs(this.searchDate[0]).format('YYYY-MM-DD'),
        end: dayjs(this.searchDate[1]).format('YYYY-MM-DD')
      }).then(res => {
        if (res.data.code === 0) {
          console.log('活跃度', res.data.data)
          this.renderChart(res.data.data)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    },

    renderChart (data) {
      console.log(data)
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.chartPane)
      }
      const options = geLineOptions(data)
      this.chart.setOption(options, true)
    },

    // 进入官网的搜索系统
    onGoSearch () {
      window.open(`${this.homePahe}/resource-search`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .home {
    .home_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 120px;
      padding-top: 10px;
      .header_left {
        & > h1 {
          font-size: 24px;
          font-weight: bold;
          line-height: 40px;
          color: #000000;
        }
        & > p {
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          color: #909399;
        }
      }
      .header_right {
        display: flex;
        width: 737px;
        .right_item {
          flex: 1;
          display: flex;
          align-items: center;
          .icon_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            min-width: 40px;
            height: 40px;
            background: #F2F2F2;
            border: 1px solid #C0C4CC;
            border-radius: 50%;
          }
          .number_box {
            flex: 1;
            text-align: center;
            padding-right: 20px;
            & > p {
              &:first-child {
                font-size: 24px;
                font-weight: bold;
                line-height: 30px;
                color: #0058DB;
              }
              &:last-child {
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                color: #606266;
              }
            }
          }
        }
      }
    }

    .home_main {
      .system_entrance_box {
        .entrance_list {
          display: flex;
          flex-wrap: wrap;
          .system_entrance {
            display: flex;
            align-items: center;
            width: 366px;
            height: 40px;
            background: #FFFFFF;
            margin-right: 20px;
            margin-bottom: 20px;
            border-radius: 2px;
            border: 1px solid #DCDFE6;
            padding: 0 12px;
            cursor: pointer;
            & > i {
              color: #0058DB;
              margin-right: 23px;
            }
            & > p {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #31394C;
            }
            &:hover {
              border: 1px solid #3473E6;
              & > p {
                color: #3473E6;
              }
            }
          }
        }
      }
      .statistics {
        display: flex;
        justify-content: space-between;
        .chart_main {
          padding: 0 10px;
          padding-bottom: 20px;
          .main_header {
            margin-bottom: 20px;
          }
          .chart_pane {
            width: 100%;
            height: 342px;
          }
        }
        .total {
          .total_main {
            padding: 0 10px;
            .total_item {
              padding-bottom: 30px;
              .top_box {
                & > p {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 20px;
                  color: #909399;
                }
                & > div {
                  display: flex;
                  // align-items: baseline;
                  align-items: center;
                  height: 50px;
                  margin-bottom: 10px;
                  & > h1 {
                    font-size: 30px;
                    font-weight: bold;
                    color: #303133;
                    & > span {
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 17px;
                      color: #909399;
                      margin-left: 8px;
                    }
                  }
                }
              }
              .footer_box {
                .footer_item {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 30px;
                  .label {
                    width: 70px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 17px;
                    color: #909399;
                  }
                  .value {
                    display: flex;
                    align-items: center;
                    & > p {
                      font-size: 14px;
                      font-weight: bold;
                      line-height: 20px;
                      color: #F36A6E;
                      margin-right: 8px;
                    }
                    & > i {
                      color: #F36A6E;
                    }
                    &.down {
                      & > p {
                        color: #606266;
                      }
                      & > i {
                        transform: rotateX(180deg);
                        color: #6AC244;
                      }
                    }
                  }
                }
              }
              &+.total_item {
                border-top: 1px dashed #DCDFE6;
                padding-top: 20px;
              }
            }
          }
        }
      }
      .module_box {
        width: 100%;
        background-color: #fff;
        padding: 0 20px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        overflow: hidden;
        .module_box_header {
          display: flex;
          align-items: center;
          height: 50px;
          margin-bottom: 10px;
          &::before {
            content: "";
            width: 3px;
            height: 20px;
            background: #3473E6;
            border-radius: 1px;
            margin-right: 10px;
          }
          & > p {
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            color: #31394C;
          }
        }
        // .module_box_main {

        // }
      }
    }
  }
</style>
